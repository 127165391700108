import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
const Experience = [
  {
    title: "Proyecto SUMA",
    area: <FormattedMessage id="experienceArea1" />,
    date: <FormattedMessage id="experienceDate1" />,
    description: <FormattedMessage id="experienciaDesc1" />,
    skills: ["Python", "Pandas", "Django", "Chart Js", "Bootstrap"],
    link: "",
  },
  {
    title: "Eitecknologia",
    area: <FormattedMessage id="experienceArea2" />,
    date: <FormattedMessage id="experienceDate2" />,
    description: <FormattedMessage id="experienciaDesc2" />,
    skills: [
      <FormattedMessage id="experienceArea2" />,
      "Python",
      "Machine Learning",
      "SQL",
      "Pandas",
      "Scikitlearn",
      "Matplotlib",
      "Microsoft Power BI",
    ],
    link: "",
  },
  {
    title: "Prediqt",
    area: <FormattedMessage id="experienceArea3" />,
    date: <FormattedMessage id="experienceDate3" />,
    description: <FormattedMessage id="experienciaDesc3" />,
    skills: [
      "A/B Test",
      "Python",
      "SQL",
      "Machine Learning",
      "Pandas",
      "Scikitlearn",
      "Matplotlib",
      "Microsoft Power BI",
      "Google Cloud",
      "Microsoft Azure",
    ],
    link: "",
  },
]

export default Experience
