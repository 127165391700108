import React, { useEffect } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import TextLoop from "react-text-loop"
//import Particles from "react-particles-js"
import ButtonHeader from "../../components/Buttons/ButtonComponent"
import SocialShare from "../../constants/Social"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import Background from "./Background/Background"
const SlideList = [
  {
    textPosition: "text-left",
    category: <FormattedMessage id="banner1" />,
    description: "",
    buttonText: "",
    buttonLink: "",
  },
]
const Hero = () => {
  useEffect(() => {
    Aos.init({ duration: 500 })
  }, [])
  return (
    <div id="home" className="fix">
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
            style={{ background: `#161d29` }}
            key={index}
          >
            {/* <Particles
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
              }}
              params={{
                particles: {
                  number: {
                    value: 170,
                    density: {
                      enable: false,
                    },
                  },
                  size: {
                    value: 3,
                    random: true,
                    anim: {
                      speed: 5,
                      size_min: 0.3,
                    },
                  },
                  line_linked: {
                    enable: false,
                  },
                  move: {
                    random: true,
                    speed: 1.5,
                    direction: "top",
                    out_mode: "out",
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "bubble",
                    },
                    onclick: {
                      enable: true,
                      mode: "repulse",
                    },
                  },
                  modes: {
                    bubble: {
                      distance: 250,
                      duration: 2,
                      size: 0,
                      opacity: 0,
                    },
                    repulse: {
                      distance: 400,
                      duration: 4,
                    },
                  },
                },
              }}
            /> */}
            <Background
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
              }}
            />

            <div data-aos="zoom-in" className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    <h1 className="title">
                      <FormattedMessage id="banner2" />
                      <br />
                      <TextLoop>
                        <span>
                          <FormattedMessage id="banner3" />
                        </span>
                        <span>
                          <FormattedMessage id="banner4" />
                        </span>
                        {/*    <span>
                              <FormattedMessage id="banner5" />
                            </span> */}
                        <span>
                          <FormattedMessage id="banner6" />
                        </span>
                      </TextLoop>{" "}
                    </h1>
                    <h2>
                      <FormattedMessage id="from" />
                    </h2>
                    <ButtonHeader text={<FormattedMessage id="cvbutton" />} />
                    <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                      {SocialShare.map((val, i) => (
                        <li key={i}>
                          <a
                            href={`${val.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`${val.name}`}
                          >
                            {val.Social}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`}
                        >
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/*    <div className="col-lg-4" style={{background: ''}}>
                      <p>assdsdsa</p>
                      <h2>asasaa</h2>
                    </div> */}
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
    </div>
  )
}

export default Hero
