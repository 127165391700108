import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"
const PortfolioListContent = [
  {
    image: (
      <StaticImage
        src="../images/shrimpv2.webp"
        placeholder="blurred"
        alt="acuicultura"
      />
    ),
    category: <FormattedMessage id="projectscategory1" />,
    title: <FormattedMessage id="projecttitle1" />,
    link: "",
  },
  {
    image: (
      <StaticImage
        src="../images/callcenter.webp"
        placeholder="blurred"
        alt="callcenter"
      />
    ),
    category: <FormattedMessage id="projectscategory2" />,
    title: <FormattedMessage id="projecttitle2" />,
    link: "",
  },

  {
    image: (
      <StaticImage
        src="../images/portfolio3v3.png"
        placeholder="blurred"
        alt="SUMA"
      />
    ),
    category: <FormattedMessage id="projectscategory3" />,
    title: <FormattedMessage id="projecttitle3" />,
    link: "",
  },
]

export default PortfolioListContent
