import React from "react"
import "./SkillBar.scss"
const SkillBar = ({ value }) => {
  return (
    <div className="containerSkillBar">
      <div className="progressbar-container">
        <div className="progressbar-complete" style={{ width: `${value}%` }}>
          <div className="progressbar-liquid"></div>
        </div>
        <span className="progress">{value}%</span>
      </div>
    </div>
  )
}

export default SkillBar
