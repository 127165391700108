import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import "./ButtonComponentStyle.scss"
import PDFEspaniol from "../../assets/resumeEsp.pdf"
import PDFIngles from "../../assets/resumeEng.pdf"

const ButtonComponent = ({ text }) => {
  const languaje = useIntl()
  return (
    <>
      {languaje.locale === "es" ? (
        <a href={PDFEspaniol} target="_blank" rel="noreferrer">
          <button className="button type3">{text}</button>
        </a>
      ) : (
        <a href={PDFIngles} target="_blank" rel="noreferrer">
          <button className="button type3">{text}</button>
        </a>
      )}
    </>
  )
}

export default ButtonComponent
