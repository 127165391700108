import React, { useState } from "react"
//import emailjs from "emailjs-com"
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import { useForm, ValidationError } from "@formspree/react"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ContactThree = () => {
  const intl = useIntl()
  const [states, setstates] = useState({
    rnName: "",
    rnEmail: "",
    rnSubject: "",
    rnMessage: "",
  })
  const [state, handleSubmit] = useForm("xeqrdgly")
  const [open, setOpen] = React.useState(false)
  const handleAatribbutes = () => {
    if (states.rnName && states.rnEmail) {
    } else {
      setOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <div className="contact-form--1">
      <div className="container">
        <center>
          <div className="col-lg-8 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">
                <FormattedMessage id="contactButton" />
              </h2>
              <p className="description">
                <FormattedMessage id="contactText" />
                <a href="mailto:bravoariel1234@gmail.com">
                  {" "}
                  arielbravo.ec@gmail.com
                </a>{" "}
              </p>
            </div>
            <div className="form-wrapper">
              {state.succeeded ? (
                <Alert severity="success">
                  <FormattedMessage id="emailEnviado" />
                </Alert>
              ) : (
                <form onSubmit={handleSubmit}>
                  <label
                    style={{ float: "left", width: "48%" }}
                    htmlFor="item01"
                  >
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      value={states.rnName}
                      onChange={e => {
                        setstates({ ...states, rnName: e.target.value })
                      }}
                      placeholder={intl.formatMessage({
                        id: "namePlaceHolder",
                      })}
                      required
                    />
                    <ValidationError
                      field="name"
                      prefix="name"
                      errors={state.errors}
                    />
                  </label>

                  <label
                    style={{ float: "right", width: "48%" }}
                    htmlFor="item02"
                  >
                    <input
                      type="email"
                      name="email"
                      id="item02"
                      value={states.rnEmail}
                      onChange={e => {
                        setstates({ ...states, rnEmail: e.target.value })
                      }}
                      placeholder={intl.formatMessage({
                        id: "emailPlaceHolder",
                      })}
                      required
                    />
                    <ValidationError
                      field="email"
                      prefix="Email"
                      errors={state.errors}
                      style={{ color: "Red", marginBottom: "10px" }}
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      value={states.rnSubject}
                      onChange={e => {
                        setstates({ ...states, rnSubject: e.target.value })
                      }}
                      placeholder={intl.formatMessage({
                        id: "subjectPlaceHolder",
                      })}
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      value={states.rnMessage}
                      onChange={e => {
                        setstates({ ...states, rnMessage: e.target.value })
                      }}
                      placeholder={intl.formatMessage({
                        id: "messagePlaceHolder",
                      })}
                    />
                    <ValidationError
                      field="message"
                      prefix="message"
                      errors={state.errors}
                    />
                  </label>
                  <button
                    className="rn-btn btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                    disabled={state.submitting}
                    onClick={handleAatribbutes}
                  >
                    <FormattedMessage id="contactSectionButton" />
                  </button>
                </form>
              )}
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  <FormattedMessage id="messageAlert" />
                </Alert>
              </Snackbar>
            </div>
          </div>
        </center>
      </div>
    </div>
  )
}
export default ContactThree
