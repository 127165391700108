import React, { useEffect } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import "./TimeLine.scss"
import { FormattedMessage } from "gatsby-plugin-react-intl"
const TimeLine = ({ list }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <div id="cd-timeline" class="cd-container">
      {list.map((item, key) => (
        <div className="cd-timeline-block" key={key}>
          <div className="cd-timeline-img cd-picture"></div>

          <div
            className="cd-timeline-content"
            style={item.important ? { border: "3px solid white" } : {}}
            data-aos={key % 2 === 0 ? "zoom-out-right" : "zoom-out-left"}
          >
            <h3>{item.title}</h3>
            <div className="timeline-content-info">
              <span className="timeline-content-info-title">
                <i className="fa fa-certificate" aria-hidden="true"></i>
                {item.area}
              </span>
              <span className="timeline-content-info-date">
                <i className="fa fa-calendar-o" aria-hidden="true"></i>
                {item.date}
              </span>
            </div>
            <p>{item.description}</p>
            <ul className="content-skills">
              {item.skills.map((skill, key) => (
                <li key={key}>{skill}</li>
              ))}
            </ul>
            {item.link ? (
              <div style={{ textAlign: "center" }}>
                <a
                  href={item.link}
                  className="rn-btn buttonTimeline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="certificadoButton" />
                </a>
              </div>
            ) : null}
          </div>
        </div>
      ))}

      {/*  <div class="cd-timeline-block">
        <div class="cd-timeline-img cd-movie"></div>

        <div class="cd-timeline-content">
          <h2>Title of section 2</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto,
            optio, dolorum provident rerum aut hic quasi placeat iure tempora
            laudantium ipsa ad debitis unde?
          </p>
          <span class="cd-date">Jan 18</span>
        </div>
      </div> */}
    </div>
  )
}

export default TimeLine
