import React, { Component } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import { StaticImage } from "gatsby-plugin-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"
class BrandTwo extends Component {
  componentDidMount() {
    Aos.init({ duration: 1000 })
  }
  render() {
    return (
      <React.Fragment>
        <p
          style={{
            fontSize: "18px",
            lineHeight: "30px",
            textAlign: "center",
            color: "#6c757d",
          }}
        >
          <FormattedMessage id="brandsText" />
        </p>

        <ul style={{ filter: "invert(1)" }} className="brand-style-2">
          <li data-aos="zoom-out-up">
            <StaticImage
              src="../images/logo-lightprediqt.webp"
              placeholder="blurred"
              alt="Prediqt"
            />
          </li>
          <li data-aos="zoom-out-up">
            <StaticImage
              src="../images/vitaprov2.png"
              placeholder="blurred"
              alt="Prediqt"
            />
          </li>
          <li data-aos="zoom-out-up">
            <StaticImage
              src="../images/bravoHatsfinalWeb1.svg"
              placeholder="blurred"
              alt="Bravo Hats"
            />
          </li>
          <li data-aos="zoom-out-up">
            <StaticImage
              src="../images/ucuenca.png"
              placeholder="blurred"
              alt="Universidad de Cuenca"
            />
          </li>
          <li data-aos="zoom-out-up">
            <StaticImage
              src="../images/eiteck.png"
              placeholder="blurred"
              height={50}
              alt="Eiteck"
            />
          </li>
          <li data-aos="zoom-out-up">
            <StaticImage
              src="../images/braccount.svg"
              placeholder="blurred"
              alt="Braccount"
            />
          </li>
        </ul>
      </React.Fragment>
    )
  }
}
export default BrandTwo
