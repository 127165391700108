import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const Education = [
  {
    title: <FormattedMessage id="educationTitle1" />,
    area: <FormattedMessage id="educationArea1" />,
    date: <FormattedMessage id="educationDate1" />,
    description: <FormattedMessage id="educationDesc1" />,
    skills: [
      <FormattedMessage id="educationArea1" />,
      <FormattedMessage id="educationBadge1" />,
      <FormattedMessage id="educationBadge2" />,
      <FormattedMessage id="educationBadge3" />,
    ],
    link: "",
    important: false,
  },

  {
    title: <FormattedMessage id="educationTitle6" />,
    area: <FormattedMessage id="educationArea6" />,
    date: <FormattedMessage id="educationDate6" />,
    description: <FormattedMessage id="educationDesc6" />,
    skills: [
      <FormattedMessage id="educationArea6" />,
      <FormattedMessage id="educationBadge11" />,
      "Python",
      "Pandas",
      "Numpy",
    ],
    link:
      "https://www.coursera.org/account/accomplishments/certificate/CVJ42F2JR8JG",
    important: false,
  },
  {
    title: <FormattedMessage id="educationTitle7" />,
    area: <FormattedMessage id="educationArea6" />,
    date: <FormattedMessage id="educationDate6" />,
    description: <FormattedMessage id="educationDesc7" />,
    skills: [<FormattedMessage id="educationArea6" />, "Python", "Matplotlib"],
    link:
      "https://www.coursera.org/account/accomplishments/certificate/FWSKY3DMSSJK",
    important: false,
  },
  {
    title: <FormattedMessage id="educationTitle8" />,
    area: <FormattedMessage id="educationArea6" />,
    date: <FormattedMessage id="educationDate8" />,
    description: <FormattedMessage id="educationDesc8" />,
    skills: [
      <FormattedMessage id="educationArea6" />,
      "Python",
      <FormattedMessage id="educationBadge12" />,
      "SkiLearn",
    ],
    link:
      "https://www.coursera.org/account/accomplishments/certificate/6RL5DFC769JX",
    important: false,
  },
  {
    title: <FormattedMessage id="educationTitle9" />,
    area: <FormattedMessage id="educationArea6" />,
    date: <FormattedMessage id="educationDate8" />,
    description: <FormattedMessage id="educationDesc9" />,
    skills: [
      <FormattedMessage id="educationArea6" />,
      "Python",
      <FormattedMessage id="educationBadge13" />,
    ],
    link:
      "https://www.coursera.org/account/accomplishments/certificate/CW5ALV96653A",
    important: false,
  },
  {
    title: <FormattedMessage id="educationTitle10" />,
    area: <FormattedMessage id="educationArea6" />,
    date: <FormattedMessage id="educationDate8" />,
    description: <FormattedMessage id="educationDesc10" />,
    skills: [
      <FormattedMessage id="educationArea6" />,
      "Python",
      <FormattedMessage id="educationBadge14" />,
    ],
    link:
      "https://www.coursera.org/account/accomplishments/certificate/BRG4UZC48DAP",
    important: false,
  },
  {
    title: <FormattedMessage id="educationTitle11" />,
    area: <FormattedMessage id="educationArea6" />,
    date: <FormattedMessage id="educationDate8" />,
    description: <FormattedMessage id="educationDesc11" />,
    skills: [
      <FormattedMessage id="educationArea6" />,
      "Python",
      <FormattedMessage id="educationBadge15" />,
      <FormattedMessage id="educationBadge11" />,
      <FormattedMessage id="educationBadge16" />,
      <FormattedMessage id="educationBadge12" />,
      <FormattedMessage id="educationBadge13" />,
      <FormattedMessage id="educationBadge14" />,
    ],
    link:
      "https://www.coursera.org/account/accomplishments/specialization/certificate/GH7MHLYQEBB4",
    important: true,
  },
  {
    title: <FormattedMessage id="educationTitle12" />,
    area: <FormattedMessage id="educationArea12" />,
    date: <FormattedMessage id="educationDate12" />,
    description: <FormattedMessage id="educationDesc12" />,
    skills: [
      <FormattedMessage id="educationArea12" />,
      "Microsoft Power BI",
      "DAX",
      "Query",
      "KPI",
      <FormattedMessage id="educationBadge16" />,
      <FormattedMessage id="educationBadge17" />,
      "Dashboard",
    ],
    link:
      "https://www.udemy.com/certificate/UC-3a820bd3-1fcb-4cdb-8391-97aba29b4d7a/",
    important: false,
  },
  {
    title: <FormattedMessage id="educationTitle13" />,
    area: <FormattedMessage id="educationArea13" />,
    date: <FormattedMessage id="educationDate13" />,
    description: <FormattedMessage id="educationDesc13" />,
    skills: [
      <FormattedMessage id="educationBadge18" />,
      "Software",
      "Hardware",
      <FormattedMessage id="educationBadge19" />,
      <FormattedMessage id="educationBadge20" />,
      "Web",
      <FormattedMessage id="educationBadge21" />,
      <FormattedMessage id="educationBadge22" />,
      <FormattedMessage id="educationBadge23" />,
      <FormattedMessage id="educationBadge24" />,
      <FormattedMessage id="educationBadge11" />,
      <FormattedMessage id="educationBadge25" />,
    ],
    important: true,
  },
  {
    title: <FormattedMessage id="educationTitle14" />,
    area: <FormattedMessage id="educationArea14" />,
    date: <FormattedMessage id="educationDate14" />,
    description: <FormattedMessage id="educationDesc14" />,
    skills: [
      <FormattedMessage id="educationArea6" />,
      <FormattedMessage id="educationBadge16" />,
      <FormattedMessage id="educationBadge11" />,
      <FormattedMessage id="educationBadge12" />,
      <FormattedMessage id="educationBadge13" />,
      <FormattedMessage id="educationBadge15" />,
      <FormattedMessage id="educationBadge20" />,
      <FormattedMessage id="educationBadge25" />,
      <FormattedMessage id="educationBadge26" />,
      <FormattedMessage id="educationBadge27" />,
    ],

    important: true,
  },
]

export default Education
