import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
const MainSkills = [
  /*   {
    title: <FormattedMessage id="skills1" />,
    value: "95",
  }, */
  {
    title: <FormattedMessage id="skills2" />,
    value: "95",
  },
  {
    title: <FormattedMessage id="skills3" />,
    value: "90",
  },
  {
    title: <FormattedMessage id="skills4" />,
    value: "95",
  },
  /*   {
    title: <FormattedMessage id="skills5" />,
    value: "100",
  }, */
  {
    title: <FormattedMessage id="skills6" />,
    value: "80",
  },
]

export default MainSkills
