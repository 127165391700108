import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import "./TechnologyStack.scss"
import { StaticImage } from "gatsby-plugin-image"
/* import Particles from "react-particles-js" */

const TechnologyStack = () => {
  return (
    <div style={{ background: `#161d29`, position: "relative" }}>
      <div
        className="about-wrapper ptb--120 "
        style={{ paddingBottom: "100px" }}
      >
        {/*  <Particles
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
          }}
          params={{
            particles: {
              number: {
                value: 35,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        /> */}
        <div className="container">
          <div className="row row--65 align-items-center">
            <div className="col-lg-12">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title" style={{ textTransform: "uppercase" }}>
                    <FormattedMessage id="technologyTitle" />
                  </h2>
                  <div>
                    <div className="row mt--30">
                      <div className="col-lg-2" style={{ padding: "35px" }}>
                        <div style={{ textAlign: "center" }}>
                          <StaticImage
                            src="../../images/datascienceicon.svg"
                            placeholder="blurred"
                            alt="Data Science"
                          />
                          <h5 style={{ textTransform: "uppercase" }}>
                            <FormattedMessage id="skillHeader1" />
                          </h5>
                        </div>
                      </div>
                      <div
                        className="col-lg-10 tecnologies"
                        style={{ padding: "35px" }}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/python-5.svg"
                                width={48}
                                placeholder="blurred"
                                alt="Python"
                              />
                              <p>Python</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/numpy-logo.svg"
                                width={48}
                                placeholder="blurred"
                                alt="Numpy"
                              />
                              <p>Numpy</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/pandas.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Pandas"
                              />{" "}
                              <p>Pandas</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/tensorflow-tf.svg"
                                width={48}
                                placeholder="blurred"
                                alt="Tensorflow"
                              />
                              <p>TensorFlow</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/scipy.svg"
                                width={48}
                                placeholder="blurred"
                                alt="Scipy"
                              />
                              <p>Scipy</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/scikit-learn.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Scikit-learn"
                              />
                              <p>Scikit-learn</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 pt-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/matplotlib.svg"
                                height={48}
                                width={60}
                                placeholder="blurred"
                                alt="MatplotLib"
                              />
                              <p>MatplotLib</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 pt-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/icons8-power-bi.svg"
                                width={48}
                                placeholder="blurred"
                                alt="Power BI"
                              />
                              <p>Power BI</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt--0">
                      <div className="col-lg-2" style={{ padding: "35px" }}>
                        <div style={{ textAlign: "center" }}>
                          <StaticImage
                            src="../../images/dataen.png"
                            width={80}
                            placeholder="blurred"
                            alt="Frontend"
                          />
                          <h5>
                            <FormattedMessage id="skillHeader3" />{" "}
                          </h5>
                        </div>
                      </div>
                      <div
                        className="col-lg-10 tecnologies"
                        style={{ padding: "35px" }}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/python-5.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Pyhton"
                              />
                              <p>Python</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/archivo-sql.png"
                                height={48}
                                placeholder="blurred"
                                alt="Redux"
                              />
                              <p>SQL</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/apache_spark.png"
                                height={48}
                                placeholder="blurred"
                                alt="HTML"
                              />
                              <p>Spark</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/databricks.png"
                                height={48}
                                placeholder="blurred"
                                alt="CSS3"
                              />
                              <p>Databricks</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/hadoopv3.png"
                                placeholder="blurred"
                                height={48}
                                alt="Boostrap"
                              />
                              <p>Hadoop</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/hive.png"
                                height={48}
                                placeholder="blurred"
                                alt="Gatsby"
                              />
                              <p>Hive</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt--0">
                      <div className="col-lg-2" style={{ padding: "35px" }}>
                        <div style={{ textAlign: "center" }}>
                          <StaticImage
                            src="../../images/backendicon.png"
                            width={68}
                            placeholder="blurred"
                            alt="Backend"
                          />
                          <h5>ML Engineering</h5>
                        </div>
                      </div>
                      <div
                        className="col-lg-10 tecnologies"
                        style={{ padding: "35px" }}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-sm-2 col-4">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/python-5.svg"
                                width={48}
                                placeholder="blurred"
                                alt="Django"
                              />
                              <p>Python</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/cicd2.png"
                                placeholder="blurred"
                                width={88}
                                alt="Continuos Integration"
                              />
                              <p>CI/CD</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/docker.png"
                                placeholder="blurred"
                                width={78}
                                alt="Docker"
                              />
                              <p>Docker</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/mlflow.png"
                                placeholder="blurred"
                                width={108}
                                alt="Docker"
                              />
                              <p>mlflow</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt--0">
                      <div className="col-lg-2" style={{ padding: "35px" }}>
                        <div style={{ textAlign: "center" }}>
                          <StaticImage
                            src="../../images/icons8-database.png"
                            width={62}
                            placeholder="blurred"
                            alt="Database"
                          />
                          <h5 style={{ textTransform: "uppercase" }}>
                            <FormattedMessage id="educationBadge19" />
                          </h5>
                        </div>
                      </div>
                      <div
                        className="col-lg-10 tecnologies"
                        style={{ padding: "35px" }}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/mongodb-icon.svg"
                                placeholder="blurred"
                                alt="Mongo DB"
                              />
                              <p>Mongo DB</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/postgresql-icon.svg"
                                width={48}
                                placeholder="blurred"
                                alt="PostgreSQL"
                              />
                              <p>PostgreSQL</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/mysql-ar21.svg"
                                height={48}
                                placeholder="blurred"
                                alt="MySQL"
                              />
                              <p>MySQL</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt--0">
                      <div className="col-lg-2" style={{ padding: "35px" }}>
                        <div style={{ textAlign: "center" }}>
                          <StaticImage
                            src="../../images/toolsIcon.png"
                            width={62}
                            placeholder="blurred"
                            alt="Tools"
                          />
                          <h5 style={{ textTransform: "uppercase" }}>
                            <FormattedMessage id="technologyCategory1" />
                          </h5>
                        </div>
                      </div>
                      <div
                        className="col-lg-10 tecnologies"
                        style={{ padding: "35px" }}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/git-scm-icon.svg"
                                placeholder="blurred"
                                alt="Git"
                              />
                              <p>Git</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/github-tile.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Github"
                              />
                              <p>Github</p>
                            </div>
                          </div>

                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/icons8-pycharm.svg"
                                placeholder="blurred"
                                alt="PyCharm"
                              />
                              <p>PyCharm</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/icons8-anaconda.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Anaconda"
                              />
                              <p>Anaconda</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/visualstudio_code-icon.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Visual Studio Code"
                              />
                              <p>Visual Studio Code</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/circle ci.png"
                                height={48}
                                placeholder="blurred"
                                alt="Google Analytics"
                              />
                              <p>Circle CI</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt--0">
                      <div className="col-lg-2" style={{ padding: "35px" }}>
                        <div style={{ textAlign: "center" }}>
                          <StaticImage
                            src="../../images/deployIcon.png"
                            width={62}
                            placeholder="blurred"
                            alt="Deployment"
                          />
                          <h5 style={{ textTransform: "uppercase" }}>
                            <FormattedMessage id="technologyCategory2" />
                          </h5>
                        </div>
                      </div>
                      <div
                        className="col-lg-10 tecnologies"
                        style={{ padding: "35px" }}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/amazon-web-services-logo.svg"
                                height={48}
                                placeholder="blurred"
                                alt="Amazon Web Services"
                              />
                              <p>Amazon Web Services</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/googlecloud.png"
                                height={48}
                                placeholder="blurred"
                                alt="Heroku"
                              />
                              <p>Google Cloud</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-6">
                            <div style={{ textAlign: "center" }}>
                              <StaticImage
                                src="../../images/azure.png"
                                height={48}
                                placeholder="blurred"
                                alt="Netlify"
                              />
                              <p>Azure</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechnologyStack
