import React, { Component } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import PortfolioListContent from "../../constants/Portfolio"

class PortfolioList extends Component {
  componentDidMount() {
    Aos.init({ duration: 1000 })
  }
  render() {
    const { column, styevariation } = this.props
    const list = PortfolioListContent.slice(0, this.props.item)
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index} data-aos="zoom-in">
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail`}>{value.image}</div>
                <div className={`bg-blr-image`}>{value.image}</div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    {value.link ? (
                      <a
                        href={value.link}
                        rel="noreferrer"
                        target="_blank"
                        aria-label={`${value.title}`}
                      >
                        {value.title}
                      </a>
                    ) : (
                      <>{value.title}</>
                    )}
                  </h4>
                  <div className="portfolio-button">
                    {value.link ? (
                      <a
                        className="rn-btn"
                        href={value.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FormattedMessage id="buttonViewDetails" />
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }
}
export default PortfolioList
