import React from "react"
/* import Aos from "aos"
import "aos/dist/aos.css"  */
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import TabTwo from "../../../elements/tab/TabTwo"
import "./About.scss"

const About = () => {
  /*     useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])  */
  return (
    <div id="about" className="fix">
      <div className="about-area ptb--120  " style={{ background: "#111926" }}>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--65 align-items-center">
              <div className="col-lg-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title" style={{ marginBottom: "50px" }}>
                      <FormattedMessage id="aboutSectionTitle" />
                    </h2>
                    <div>
                      <div className="row align-items-center">
                        <div className="col-md-6 ">
                          <div className="thumbnail">
                            <StaticImage
                              className="w-100 aboutImage"
                              src="../../../images/about.JPG"
                              alt="Ariel Bravo"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p
                            className="description"
                            style={{ marginTop: "15px" }}
                          >
                            <FormattedMessage id="about1" /> <br />
                          </p>
                          <p className="description">
                            <FormattedMessage id="about2" />
                          </p>
                          <p className="description">
                            <FormattedMessage id="about3" />
                          </p>
                          <p>
                            <FormattedMessage id="about4" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt--30">
          <TabTwo tabStyle="tab-style--1" />
        </div>
      </div>
    </div>
  )
}

export default About
