import React, { Component } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import services from "../../constants/Services"
// import { FiCast, FiLayers, FiUsers } from "react-icons/fi";
// import DesignLogo from "../../dark/designer.svg";
import { FormattedMessage } from "gatsby-plugin-react-intl"
class ServiceThree extends Component {
  componentDidMount() {
    Aos.init({ duration: 1000 })
  }
  render() {
    const { column } = this.props
    const ServiceContent = services.slice(0, this.props.item)

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i} data-aos="zoom-out-up">
              <div>
                <div className="service service__style--2">
                  <center>
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                      <h4 className="title">
                        <FormattedMessage id="serviceSubTtitle" />
                      </h4>
                      <p>{val.things}</p>
                      <h4 className="title">
                        <FormattedMessage id="serviceSubTtitle2" />
                      </h4>
                      <p>{val.tools}</p>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}
export default ServiceThree
