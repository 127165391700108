import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/Layout"
import { FiChevronUp } from "react-icons/fi"
import ScrollToTop from "react-scroll-up"
import HeaderThree from "../components/HomePage/header/HeaderThree"
import TechnologyStack from "../components/TechnologyStack/TechnologyStack"
import About from "../components/HomePage/About/About"
import FooterTwo from "../components/HomePage/footer/FooterTwo"
import ServiceList from "../elements/service/ServiceList"
import PortfolioList from "../elements/portfolio/PortfolioList"
import BrandTwo from "../components/BrandTwo"
import ContactThree from "../elements/contact/ContactThree"
import Hero from "../components/HomePage/Hero"
import Seo from "../components/Seo"

export default function Home() {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        titlePage={`${intl.formatMessage({ id: "titleIndex" })}`}
        descriptionPage={`${intl.formatMessage({ id: "descIndex" })}`}
        keywords={[
          `Ariel Bravo`,
          `${intl.formatMessage({ id: "IndexKeyword2" })}`,
          `${intl.formatMessage({ id: "IndexKeyword3" })}`,
          `${intl.formatMessage({ id: "IndexKeyword4" })}`,
          `${intl.formatMessage({ id: "IndexKeyword5" })}`,
          `${intl.formatMessage({ id: "IndexKeyword6" })}`,
          `${intl.formatMessage({ id: "IndexKeyword7" })}`,
          `${intl.formatMessage({ id: "IndexKeyword8" })}`,
          `${intl.formatMessage({ id: "IndexKeyword9" })}`,
        ]}
        lang={intl.locale}
      />
      <div className="active-dark">
        <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
        {/* Start Slider Area   */}
        <Hero />
        <About />
        <TechnologyStack />
        {/* Start Service Area  */}
        <div id="service" className="fix">
          <div
            className="service-area creative-service-wrapper ptb--120 "
            style={{ background: `#111926` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">
                      <FormattedMessage id="service" />
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row creative-service">
                <div className="col-lg-12">
                  <ServiceList
                    item="3"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        {/* Start Portfolio Area */}
        <div id="portfolio" className="fix">
          <div
            className="portfolio-area ptb--120"
            style={{ background: "#161d29" }}
          >
            <div className="portfolio-sacousel-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                      <h2 className="title">
                        <FormattedMessage id="projectssectionTitle" />
                      </h2>
                      <p>
                        <FormattedMessage id="projectsDesc" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <PortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="7"
                  />
                </div>
                {/* <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <a className="rn-button-style--2 btn-solid" href="/blog">
                      <span>View More</span>
                    </a>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}
        {/* Start Brand Area */}

        <div
          className="rn-blog-area ptb--30  mb-dec--30"
          style={{ background: `#161d29` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}
        {/* Start COntact Area */}
        <div id="contact" className="fix">
          <div
            className="rn-contact-area ptb--120 "
            style={{ background: "#111926" }}
          >
            <ContactThree
              contactImages=""
              contactTitle={<FormattedMessage id="contactButton" />}
            />
          </div>
        </div>
        {/* End COntact Area */}
        <FooterTwo />
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/*  <ThemeToggler /> */}
        {/* End Back To Top */}
      </div>
      {/* <ThemeToggle />  */}
      {/* <LanguajeV2 /> */}
    </Layout>
  )
}
